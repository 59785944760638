<template>
	<div class="overview-header">
		<!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions-->
		<div
				v-for="header in headers"
				:key="header.key"
				:class="[
					header.class ? header.class : header.key,
					header.sortable ? 'sortable' : ''
				]"
				:data-test-id-header="header.key"
				:style="header.gridArea ? `grid-area: ${header.gridArea};` : null"
				class="cell header-cell"
				@click="sort(header)"
		>
			{{ header.text }}

			<button
					v-if="header.sortable"
					class="sort-button"
					@click.stop="sort(header)"
			>
				<SortIcon :sorting="getSortingState(header)" />
			</button>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import type {PropType} from 'vue';
	import type {OverviewHeaderItem} from '~/lib/types/OverviewItemType';
	import SortIcon from '~/components/generic/overview/SortIcon.vue';

	const props = defineProps({
		headers: {
			type: Array as PropType<OverviewHeaderItem[]>,
			default: () => []
		},
		sorting: {
			type: Object as PropType<{ key: string, value: 'asc' | 'desc' | null }>,
			required: false,
			default: null
		}
	});

	const emit = defineEmits<{
		sort: [key: string]
	}>();

	const sort = (header: OverviewHeaderItem) => {
		if (!header.sortable) {
			return false;
		}
		emit('sort', header.key);
	};

	const getSortingState = (header: OverviewHeaderItem) => {
		if (!props.sorting) {
			return null;
		}
		if (header.key === props.sorting.key) {
			return props.sorting.value;
		}
		return null;
	};
</script>

<style scoped>
	.overview-header {
		background: #ffffff;
		border-bottom: 1px solid var(--background-color-main);
		display: var(--overview-header-display, none);
		grid-template: var(--overview-header-grid);
		color: inherit;
		text-decoration: none;
		font-size: var(--font-size-p-1);
		line-height: var(--line-height-p-1);
		--icon-size: 16px;
		width: 100%;
	}

	.cell {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 12px 0 12px var(--cell-padding-x);
	}

	.cell:first-child {
		/* FIXME: gelijktrekken met card padding? */
		padding-left: calc(var(--cell-padding-x) * 0.5);
	}

	.status {
		grid-area: status;
		padding-left: calc(var(--cell-padding-x) * 0.5);
	}

	.sortable {
		cursor: pointer;
		user-select: none;
	}

	.sort-button {
		background: none;
		padding: 0;
		cursor: pointer;
		border: none;
		margin: 0 0 0 4px;
		width: 20px;
	}
</style>
